import React from "react"
import { graphql } from "gatsby"

import Layout from "@components/Layout"
import SEO from "@components/Seo"
import FAQs from "@components/FaqAccordian"

const FaqPage = ({ data }) => {
  const banner = data.banner.edges[0].node.childImageSharp.fluid
  const faqs = data.faqs.frontmatter
  const { questions, title } = faqs
  return (
    <Layout fluid={banner} alt={"Neil Hill Coaching"} height={300}>
      <SEO title="FAQs" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 col-md-6 mx-auto py-6 text-center">
            <FAQs questions={questions} title={title} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query FaqsPageQuery {
    banner: allFile(filter: { name: { eq: "faqs_1440x400" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    faqs: markdownRemark(frontmatter: { target: { eq: "FAQ" } }) {
      frontmatter {
        title
        questions {
          question
          answer
          secondary
        }
      }
    }
  }
`

export default FaqPage
