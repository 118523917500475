import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import Styled from "styled-components"

import { H1 } from "../StyledElements"

const Container = Styled.div`
    width: 100%;
    padding: 20px;
`
const Button = Styled(motion.button)`
    width: 100%;
    min-height: 40px;
    padding: 10px;
    font-size: 25px;
    background-color: ${({ theme }) => theme.primary};
    outline: none;
    border-radius: 1.5rem;
    cursor: pointer;
    appearance: none;
    border: none;
    margin-top: 20px;
    &:focus {
        background-color: ${({ open, theme }) =>
          open ? theme.primary : `lighten(${theme.primary}, 5%)`};
          outline: none;
    }
    &:hover {
        background-color: ${({ open }) =>
          open
            ? `rgba(255, 255, 255, 0.5) !important`
            : `rgba(212, 31, 31, 0.5) !important`};
            color: ${({ open, theme }) =>
              open ? `${theme.white} !important` : `${theme.white} !important`};
    }
`
const UL = Styled.ul`
    text-align: left;
`
const Div = Styled(motion.div)`
    padding: 20px;
    transform-origin: top center;
`
const Content = ({ children }) => (
  <Div
    variants={{ collapsed: { scale: 0.8 }, open: { scale: 1 } }}
    transition={{ duration: 0.8 }}
  >
    {children}
  </Div>
)

const Accordion = ({ question, answer, secondary }) => {
  const [open, setopen] = useState(false)
  return (
    <>
      <Button
        initial={false}
        animate={{
          backgroundColor: open ? "#ffffff" : "#d41f1f",
          color: open ? "#000000" : "#ffffff",
        }}
        onClick={() => setopen(open ? false : true)}
      >
        {question}
      </Button>
      <AnimatePresence initial={false}>
        {open && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <Content>
              {answer}
              {secondary && (
                <UL>
                  {secondary.map(item => (
                    <li key={item}>{item}</li>
                  ))}
                </UL>
              )}
            </Content>
          </motion.section>
        )}
      </AnimatePresence>
    </>
  )
}

const Faq = ({ questions, title }) => (
  <div className="container-fluid">
    <div className="row">
      <div className="col-12 pt-3 text-center">
        <H1>{title}</H1>
      </div>
    </div>
    <div className="row">
      <Container>
        {questions.map(question => (
          <Accordion
            key={question.question}
            question={question.question}
            answer={question.answer}
            secondary={question.secondary || null}
          />
        ))}
      </Container>
    </div>
  </div>
)

export default Faq
